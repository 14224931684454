import { createContext } from "react";
import { firebase } from "@project/shared";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: boolean;
  setUser: any;
  isAdmin: boolean;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

type AuthProviderProps = {
  loading: boolean;
  user: any | null;
  isAdmin?: boolean;
  setUser: any;
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const { loading, user, isAdmin, setUser, children } = props;
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
