import React from "react";
import PrivateRoute from "../withPrivateRoute";
import { Loader } from "@project/shared";
import styled from "styled-components";

import { withAdminAuthorization } from "../withAdminAuthorization";

const InitalLoader = styled.div`
  margin-top: 50vh;
  text-align: center;
`;

const Homepage = () => {
  return (
    <InitalLoader>
      <Loader size="large" />
    </InitalLoader>
  );
};

export default PrivateRoute(withAdminAuthorization(Homepage));
