import React, { useContext, useEffect, useState } from "react";
import { Result } from "antd";
import { AuthContext } from "./utils/AuthContext";

const withAdminAuthorization = (AuthComponent) => {
  function AdminComponent({ children }) {
    const { isAdmin } = useContext(AuthContext);
    const [admin, setAdmin] = useState(null);

    useEffect(() => {
      setAdmin(isAdmin);
    }, [isAdmin]);
    if (admin !== null && !admin) {
      return (
        <Result
          status="403"
          title="You are not authorized to access this page."
        />
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <AdminComponent>
          <AuthComponent {...this.props} />
        </AdminComponent>
      );
    }
  };
};

export { withAdminAuthorization };
